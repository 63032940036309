import { useState } from 'react';

import useChakraToast from '../../hooks/useChakraToast';
import { DrawerPaymentOrders } from '../../text/generalText';
import DrawerDetail from '../DrawerDetail';

export interface IDrawerSnackbar {
  title: string;
  status: string;
  enabled?: boolean;
  documentId: string;
  messageTooltip: string;
}
function DrawerSnackbar({
  title,
  status,
  enabled,
  documentId,
  messageTooltip,
}: IDrawerSnackbar) {
  const [retry, setRetry] = useState(false);
  const { ToastUtilities } = useChakraToast();
  const DURATION_IN_MILLISECONDS = 10000;

  const handleToastAction = async () => {
    ToastUtilities.error({
      description: DrawerPaymentOrders.informationNotAvailable,
      duration: DURATION_IN_MILLISECONDS,
      toastOptions: {
        isCtaLabel: true,
        ctaLabel: DrawerPaymentOrders.retry,
        ctaOnClick: () => setRetry(true),
      },
    });
  };

  return (
    <DrawerDetail
      title={title}
      status={status}
      documentId={documentId}
      enabled={enabled}
      messageTooltip={messageTooltip}
      snackbarAction={handleToastAction}
      retryDownload={retry}
      setRetryDownload={r => {
        setRetry(r);
      }}
    />
  );
}

export default DrawerSnackbar;
