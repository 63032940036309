export enum StatusNumber {
  APROBADO = '2',
  PARCIAL = '5',
  TOTAL = '4',
  PENDIENTE = '1',
  RECHAZADO = '3',
  SINOP = '99',
  SINESTADO = '0',
}
export const StatusText = {
  [StatusNumber.APROBADO]: 'Aprobado',
  [StatusNumber.PARCIAL]: 'Pago parcial disponible',
  [StatusNumber.TOTAL]: 'Pago total disponible',
  [StatusNumber.PENDIENTE]: 'Pendiente',
  [StatusNumber.RECHAZADO]: 'Rechazado',
  [StatusNumber.SINOP]: 'No disponible',
  [StatusNumber.SINESTADO]: 'No disponible',
};

export default StatusNumber;
