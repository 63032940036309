import styled from 'styled-components';

import { colors } from '../../styled/generalStyles';

const StyledLoadingScreen = styled.div`
  .loading-screen {
    height: 100vh;
    width: 100%;
    left: 0;
    background: ${colors.white};
    position: fixed;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 40px 0px 0px 0px;

    &__spinner {
      margin-bottom: 16px;
    }

    &__title {
      line-height: 22px;
      margin-bottom: 10px;
    }

    &__description {
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }
`;

export default StyledLoadingScreen;
