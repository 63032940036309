import styled from 'styled-components';

import { colors } from '../../styled/generalStyles';

const AccordionContainer = styled.div`
  .accordion-item {
    border: none;
  }
  .accordion-button-arrow {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    font-size: 24px;
    border-radius: 8px;
  }

  .accordion-button-arrow:focus {
    box-shadow: none;
  }

  .accordion-buttons {
    display: flex;
    align-items: center;
    color: ${colors.blue};
    gap: 12px;
  }
  .accordion-header {
    display: grid;
    grid-template-columns: 2fr 2fr 0.5fr;
    gap: 20px;
    height: 90px;
    padding: 8px 16px;
    border-bottom-color: var(--chakra-colors-grey-60);
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  .paymentOrderNumber-container {
    padding-top: 10px;
    margin-bottom: 20px;
    margin: auto auto auto 0px;
  }

  .company-total {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }

  .dateDrawer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
  }
  .boxCalendar {
    margin: auto;
    color: var(--chakra-colors-primary-90);
  }

  .iconCalendar {
    width: 13px;
    height: 13px;
    margin-right: 7px;
  }

  .accordionPanel {
    padding-inline-start: 0;
  }
`;

export default AccordionContainer;
