import { Accordion } from '@chakra-ui/react';

import { IPaymentOrderResponse } from '../../interfaces/IPaymentOrdersResponse';
import AccordionDrawerItem from './AccordionDrawerItem';
import AccordionContainer from './styled';

interface IDrawerAccordion {
  paymentOrders: IPaymentOrderResponse[];
}
function DrawerAccordion({ paymentOrders }: IDrawerAccordion) {
  const renderDrawerItem = () => {
    return paymentOrders.map(item => {
      return (
        <AccordionDrawerItem
          key={item.payment_order_number}
          paymentOrderResponse={item}
        />
      );
    });
  };

  return (
    <AccordionContainer>
      <Accordion className='accordion' allowMultiple defaultIndex={[0]}>
        {renderDrawerItem()}
      </Accordion>
    </AccordionContainer>
  );
}

export default DrawerAccordion;
