/* eslint-disable import/no-unresolved */
import { Grid, GridItem, Text } from '@chakra-ui/react';
import { TablePaginationComponent } from '@wow/table-pagination';
import { pushAnalyticsEvent } from '@wow/utils';
import { useWizard, WizardComponent } from '@wow/wizard';
import { useEffect, useState } from 'react';

import Filter from '../../components/Filter';
import NoResultsView from '../../components/NoResultsView';
import Table from '../../components/Table';
import { FILTER_STATUS } from '../../interfaces/IFilterProps';
import { Pagination as PaginationType } from '../../interfaces/IIssuedDocsResponse';
import { getIssuedDocuments } from '../../services/api';
import { DataHeaderHome } from '../../text/generalText';
import getIssuedDocumentsColumns from './config/IssuedDocumentsCConfig';
import { Box, Container, Link } from './styled';
import WizardSteps from './WizardSteps';

function Home() {
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(15);
  const [hasErrorConnection, setHasErrorConnection] = useState(false);
  const [issuedDocuments, setIssuedDocuments] = useState([]);
  const [sortProp, setSortProp] = useState<string>('date');
  const [sortOrder, setSortOrder] = useState<string>('desc');
  const [filterStatus, setFilterStatus] = useState(FILTER_STATUS.INITIAL);
  const [statusCodes, setStatusCodes] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [wizard, initWizard] = useWizard(WizardSteps);
  const [pagination, setPagination] = useState<PaginationType>({
    currentPage: 0,
    limit: 0,
    offset: 0,
    totalPages: 0,
    totalRows: 0,
  });

  useEffect(() => {
    fetchIssuedDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size, page, sortProp, sortOrder, statusCodes]);

  useEffect(() => {
    if (
      issuedDocuments.length > 0 &&
      localStorage.getItem('interpagos-wizard') == null
    ) {
      initWizard('interpagos-wizard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issuedDocuments]);

  useEffect(() => {
    pushAnalyticsEvent({
      event: 'trackContentGroup',
      content_group: 'Cobranzas',
    });
  }, []);

  const handleFinishWizard = () => {
    localStorage.setItem('interpagos-wizard', 'true');
    pushAnalyticsEvent({
      event: 'wizard_completed',
    });
  };

  const fetchIssuedDocuments = () => {
    if (page > 0) {
      setIsLoading(true);
      setFilterStatus(FILTER_STATUS.PENDING);
      getIssuedDocuments(size, page, sortProp, sortOrder, statusCodes.join(','))
        .then(res => {
          setIsInitialized(true);
          setIssuedDocuments(res.data.documents);
          setHasErrorConnection(false);
          setPagination(res.data.page);
          setFilterStatus(FILTER_STATUS.SUCCESS);
        })
        .catch(() => {
          setHasErrorConnection(!isInitialized);
          setFilterStatus(FILTER_STATUS.FAILED);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const setSort = (sortPropCol: string, sortOrderCol: string) => {
    setSortProp(sortPropCol);
    setSortOrder(sortOrderCol);
    pushAnalyticsEvent({
      event: 'sorting_listing',
      item_id: 'Fecha de emisión',
    });
  };

  const updateTableData = (codes: string[]) => {
    setStatusCodes([...codes]);
    setPage(1);
  };

  return (
    <>
      {wizard.initialized && (
        <WizardComponent
          id='interpagos-wizard'
          steps={WizardSteps}
          width={300}
          height={220}
          offset={0}
          onFinish={() => handleFinishWizard()}
        />
      )}
      <Grid templateColumns='repeat(12, 1fr)' gap={16}>
        <GridItem colSpan={12}>
          <Container>
            <Text id='titleInterpagos' textStyle='h1' className='textTitle'>
              {DataHeaderHome.title}
            </Text>
            <Box>
              <Text textStyle='bodyMd' mr='4px'>
                {DataHeaderHome.subtitle}
              </Text>
              <Text textStyle='labelLgBoldUnderline' display='inline'>
                <Link
                  target='blank'
                  href={process.env.COBRANZAS_EXTERNAL_URL}
                  rel='noopener noreferrer'
                >
                  {DataHeaderHome.title}
                </Link>
              </Text>
            </Box>
            {hasErrorConnection ? (
              <NoResultsView
                variant='ERROR_CONN'
                hasButton
                onRetry={fetchIssuedDocuments}
              />
            ) : (
              <>
                <Text marginTop='1.5rem' textStyle='h2Bold'>
                  {DataHeaderHome.titleSecond}
                </Text>
                <Text marginTop='0.5rem' marginBottom='0.5rem'>
                  {DataHeaderHome.subtitleSecond}
                </Text>
                <div id='filter'>
                  <Filter
                    isExistDocuments={issuedDocuments?.length > 0}
                    onFilterApply={updateTableData}
                    onRetry={fetchIssuedDocuments}
                    isLoading={isLoading}
                    filterStatus={filterStatus}
                  />
                </div>
                <div id='tableDocuments' className='tableDocuments'>
                  <Table
                    inputData={issuedDocuments}
                    columnsData={getIssuedDocumentsColumns(setSort)}
                    emptyContent={
                      <NoResultsView
                        variant={
                          issuedDocuments.length === 0
                            ? 'NO_RESULTS'
                            : 'NO_DATA'
                        }
                        hasLink={!(issuedDocuments.length === 0)}
                      />
                    }
                    startSelected
                  />
                  {!isLoading ? (
                    <TablePaginationComponent
                      totalPages={pagination.totalPages || 1}
                      currentIndex={pagination.currentPage - 1}
                      totalRecords={pagination.totalRows}
                      perPage={[15, 30, 45]}
                      disabledPerPage={pagination.totalRows <= 15}
                      currentPerPage={pagination.limit}
                      onNext={() => setPage(page + 1)}
                      onPrev={() => setPage(page - 1)}
                      onFirst={() => setPage(1)}
                      onLast={() => setPage(pagination.totalPages)}
                      onJump={(value: number) => {
                        setPage(Number(value));
                      }}
                      onPerPageChange={(value: number) => {
                        setSize(Number(value));
                        setPage(1);
                      }}
                    />
                  ) : null}
                </div>
              </>
            )}
          </Container>
        </GridItem>
      </Grid>
    </>
  );
}

export default Home;
