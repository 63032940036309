import { IconButton } from '@chakra-ui/react';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .textTitle {
    margin-top: 20px;
  }
  .snackbarPosition {
    position: fixed;
    bottom: 30px;
    left: 120px;
    z-index: 2000;
  }

  .tableDocuments {
    padding: 8px;
  }
`;

export const Link = styled.a`
  color: blue;
  text-decoration: underline;
  text-decoration-skip-ink: underline;
  &:visited {
    color: blue;
  }
`;

export const Box = styled.div`
  display: flex;
  margin-top: 8px;
`;

export const IconButtonStyled = styled<{ disabled: boolean }>(IconButton)`
  background-color: transparent !important;
  border: none;
  padding: 0;
  outline: none;
  position: inherit !important;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  ${({ disabled }) =>
    !disabled &&
    css`
      position: inherit !important;
    `}
`;

export default Container;
