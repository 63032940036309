const CurrencySymbols = {
  ARS: '$',
  USD: 'u$s',
};

const transformNumberToCurrency = (value: number, currency: string): string => {
  let result = '-';
  if (value) {
    result = Intl.NumberFormat('es-AR', {
      style: currency ? 'currency' : 'decimal',
      minimumFractionDigits: 2,
      ...(currency && { currency, currencyDisplay: 'code' }),
    }).format(value);
    result = result.replace(currency, CurrencySymbols[currency] ?? '');
  }
  return result;
};

export const getDocumentIdStringFromList = (
  list: RelatedDocument[],
): string => {
  const documents = list
    .filter(
      element =>
        element.type === 1 && element.id !== null && element.id !== undefined,
    )
    .map(element => element.id);
  return documents.join();
};

interface RelatedDocument {
  id: string;
  type: number;
}

export default transformNumberToCurrency;
