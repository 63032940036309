const isDateValid = (dateString: string): boolean => {
  // Regular expression for yyyy-MM-dd format
  const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;

  // Check if the string matches the expected format
  if (!dateFormatRegex.test(dateString)) {
    return false;
  }

  const dateReceived = new Date(dateString);

  if (!Number.isNaN(dateReceived.getTime()) && dateReceived instanceof Date)
    return true;

  return false;
};

export default isDateValid;
