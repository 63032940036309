import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Header,
  RowData,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';

import ITableProps from '../../interfaces/ITableProps';
import { Cell, TableStyled } from './styled';

function Table<T>({
  emptyContent,
  inputData = [],
  columnsData,
  startSelected,
}: ITableProps<T>) {
  const [rowSelection, setRowSelection] = useState({});
  const [columns] = useState<typeof columnsData>(() => [...columnsData]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const { getHeaderGroups, getRowModel, toggleAllRowsSelected } = useReactTable(
    {
      data: inputData ? (inputData as unknown as RowData[]) : [],
      columns,
      state: {
        rowSelection,
        sorting,
      },
      onSortingChange: setSorting,
      onRowSelectionChange: setRowSelection,
      onStateChange: setRowSelection,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      manualPagination: true,
    },
  );
  // manejo de todas las columnas seleccionadas por default
  useEffect(() => {
    if (startSelected) {
      toggleAllRowsSelected();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputData]);

  const trCell = cell => {
    const flexRenderBodyTable = flexRender(
      cell.column.columnDef.cell,
      cell.getContext(),
    );
    return <td key={cell.id}>{flexRenderBodyTable}</td>;
  };

  const thCellHeader = (header: Header<RowData, any>) => {
    const flexRenderHeaderTable = (
      <Cell>
        {header.isPlaceholder
          ? null
          : flexRender(header.column.columnDef.header, header.getContext())}
      </Cell>
    );

    return <th key={header.id}>{flexRenderHeaderTable}</th>;
  };

  return (
    <TableStyled>
      <thead>
        {getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id} className='grid-container'>
            {headerGroup.headers.map(header => thCellHeader(header))}
          </tr>
        ))}
      </thead>
      <tbody>
        {getRowModel().rows.map(row => (
          <tr key={row.id} className='grid-container'>
            {row.getVisibleCells().map(cell => trCell(cell))}
          </tr>
        ))}
        {inputData.length === 0 && (
          <tr className='emptyContent'>
            <td colSpan={columnsData.length}>{emptyContent}</td>
          </tr>
        )}
      </tbody>
    </TableStyled>
  );
}

export default Table;
