import styled from 'styled-components';

const CobranzasPanel = styled.div`
  padding-left: 22px;
  padding-right: 30px;
  padding-top: 40px;
  height: 100%;
  position: relative;
  width: 95%;
  margin-bottom: 150px;
`;

export default CobranzasPanel;
