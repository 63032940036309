import {
  DataNoResultsView,
  EmptyDocument,
  NotFoundResult,
} from '../../text/generalText';

export default {
  NO_DATA: {
    title: EmptyDocument.title,
    picture: 'no-data',
    subtitle: EmptyDocument.subtitle,
    hasButton: false,
    hasLink: true,
  },
  ERROR_CONN: {
    title: DataNoResultsView.title,
    picture: 'no-results',
    subtitle: DataNoResultsView.subtitle,
    hasButton: true,
    hasLink: false,
  },
  NO_RESULTS: {
    title: EmptyDocument.title,
    picture: 'no-data',
    subtitle: NotFoundResult.subtitle,
    hasButton: false,
    hasLink: false,
  },
};
