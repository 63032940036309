export const DataFilter = {
  subtitle: 'Filtrá por:',
  buttonFilter: 'Estado',
  menuSubtitle: 'Podés seleccionar más de uno',
  buttonDelete: 'Borrar',
  buttonApply: 'Aplicar',
};
export const DataHeaderHome = {
  title: 'Cobranzas',
  subtitle:
    'Encontrá la información que necesitás para un completo seguimiento de tus clientes. ¿Necesitás más datos? Ingresá a ',
  titleSecond: 'Tus documentos emitidos',
  subtitleSecond:
    'Descubrí la información consolidada y realizá diferentes acciones sobre tus documentos.',
};

export const DataNoResultsView = {
  title: 'La información no está disponible',
  subtitle: 'Estamos trabajando para solucionarlo, volvé a intentar más tarde.',
  buttonText: 'Reintentar',
};

export const DrawerPaymentOrders = {
  downloadButton: (totalFiles: number): string => {
    return totalFiles > 1
      ? `Descargar ${totalFiles} archivos`
      : `Descargar archivo`;
  },
  paymentOrderNumber: 'Nro de orden',
  retetionCertificate: 'Certificado de retención',
  paymentOrder: 'Orden de pago',
  titleFilesAvalaible: 'Archivos disponibles',
  titleDocumentIncluded: 'Documentos incluidos',
  nroDocumento: 'Nro de documento',
  fechaEmision: 'Fecha de emisión',
  importe: 'Importe',
  dateNotAvailable: 'Fecha no disponible',
  informationNotAvailable: 'No podemos realizar la descarga.',
  retry: 'Reintentar',
};

export const EmptyDocument = {
  title: 'Nada por aquí',
  subtitle:
    'Ingresá a Cobranzas y emití tus documentos para poder visualizarlos desde acá.',
  link: 'Ir a cobranzas',
};

export const NotFoundResult = {
  subtitle: 'Continuá la búsqueda con otra combinación de variables.',
};

export const PagesHome = {
  total: 'Total',
  register: 'registros',
  of: 'de',
  perPage: 'Registros por página',
};

export const Restore = {
  buttonRestore: 'Restablecer',
};

export const SnackbarText = {
  buttonRetry: 'Reintentar',
  errorSnackBar: 'No podemos cargar la información.',
};

export const TooltipPaymentOrderDisabled = {
  pendiente: 'Orden de pago pendiente',
  noDisponible:
    'Orden de pago no disponible. Ingresá a Cobranzas y revisá tus órdenes de pago.',
};

export const UploadScreen = {
  title: 'Estamos procesando tu información',
  subTitle: 'Esto puede demorar unos instantes.',
};

export const WizardHome = {
  titleInitInterpagos: '¡Nueva sección!',
  descInitInterpagos: 'Conocé las novedades de Cobranzas.',
  titleTable: 'Información consolidada',
  descTable:
    'Encontrá tus documentos emitidos, revisá el estado en el que están y descargá tus órdenes de pago.',
  titleFilter: 'Filtros',
  descFilter:
    'Refiná la búsqueda y encontrá tus documentos emitidos mucho más rápido.',
};
