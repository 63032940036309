import './styled/fonts';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/toast';
import {
  Fonts,
  PrivateRender,
  theme as ThemeData,
  // eslint-disable-next-line import/no-unresolved
} from '@react-ib-mf/style-guide-ui';
// eslint-disable-next-line import/no-unresolved
import { AlertChakra as Alert } from '@wow/alert';
import { BadgeChakra as Badge } from '@wow/badge';
import { BreadcrumbChakra as Breadcrumb } from '@wow/breadcrumb';
import { ButtonChakra as Button } from '@wow/button';
import { CheckboxChakra as Checkbox } from '@wow/checkbox';
import { FormChakra as Form } from '@wow/form-control';
import { FormErrorChakra as FormError } from '@wow/form-error';
import { FormLabelChakra as FormLabel } from '@wow/form-label';
import { InputChakra as Input } from '@wow/input';
import { SpinnerChakra as Spinner } from '@wow/progress-indicator';
// eslint-disable-next-line import/no-unresolved
import { themeChakra } from '@wow/themes';
import { TooltipChakra as Tooltip } from '@wow/tooltip';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import App from './App';

const { ToastContainer } = createStandaloneToast();

const theme = extendTheme({
  ...ThemeData,
  colors: {
    ...themeChakra.colors,
  },
  textStyles: {
    ...themeChakra.textStyles,
  },
  components: {
    Alert,
    Button,
    Badge,
    Breadcrumb,
    Checkbox,
    Input,
    Form,
    FormLabel,
    FormError,
    Spinner,
    Tooltip,
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: 2,
      retryDelay: attempt => attempt * 1000,
    },
  },
});

export default function Root() {
  return (
    <PrivateRender mode={process.env.NODE_ENV}>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider resetCSS theme={theme}>
          <Fonts />
          <App />
          <ToastContainer />
        </ChakraProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </PrivateRender>
  );
}
