import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { Calendar } from 'react-feather';

import { IPaymentOrderResponse } from '../../../interfaces/IPaymentOrdersResponse';
import { DrawerPaymentOrders } from '../../../text/generalText';
import transformNumberToCurrency from '../../../utils/converters';
import isDateValid from '../../../utils/dateUtils';
import AccordionDrawerItemAvalaibleFiles from '../AccordionDrawerItemAvalaibleFiles/index';
import AccordionDrawerItemDocumentIncluded from '../AccordionDrawerItemDocumentIncluded';

interface IAccordionDrawerItem {
  paymentOrderResponse: IPaymentOrderResponse;
}
function AccordionDrawerItem({ paymentOrderResponse }: IAccordionDrawerItem) {
  const getAmountToPay = () => {
    return paymentOrderResponse.amount
      ? transformNumberToCurrency(
          paymentOrderResponse.amount,
          paymentOrderResponse.currency,
        )
      : '-';
  };

  return (
    <AccordionItem className='accordion-item' data-testid='accor-item'>
      {({ isExpanded }) => (
        <>
          <Box className='accordion-header' data-testid='accor-header'>
            <Box className='paymentOrderNumber-container'>
              <Text textStyle='h5Bold'>
                {paymentOrderResponse.payment_order_number
                  ? paymentOrderResponse.payment_order_number
                  : '-'}
              </Text>
              <Text textStyle='bodyMd'>
                {DrawerPaymentOrders.paymentOrderNumber}
              </Text>
            </Box>

            <Box className='company-total'>
              <Text textStyle='h3'>{getAmountToPay()}</Text>
              <Box className='dateDrawer' role='rowheader'>
                <Box className='boxCalendar'>
                  <Calendar className='iconCalendar' />
                </Box>
                <Text textStyle='bodySmSemi'>
                  {paymentOrderResponse.date &&
                  isDateValid(paymentOrderResponse.date)
                    ? format(
                        new Date(paymentOrderResponse.date?.replace(/-/g, '/')),
                        'dd/MM/yy',
                      )
                    : DrawerPaymentOrders.dateNotAvailable}
                </Text>
              </Box>
            </Box>
            <div className='accordion-buttons'>
              <AccordionButton
                className={`accordion-button-arrow ${
                  isExpanded ? 'selected' : ''
                }`}
              >
                <AccordionIcon />
              </AccordionButton>
            </div>
          </Box>
          <AccordionPanel className='accordionPanel'>
            <AccordionDrawerItemAvalaibleFiles
              files={paymentOrderResponse.files}
            />
            <AccordionDrawerItemDocumentIncluded
              documentIncluded={paymentOrderResponse.related_documents}
            />
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
}

export default AccordionDrawerItem;
