import { StatusNumber, StatusText } from '../../interfaces/IStatusTypes';

const statusInitialState = [
  {
    state: StatusText[StatusNumber.APROBADO],
    code: [StatusNumber.APROBADO],
    isChecked: false,
  },
  {
    state: StatusText[StatusNumber.PARCIAL],
    code: [StatusNumber.PARCIAL],
    isChecked: false,
  },
  {
    state: StatusText[StatusNumber.TOTAL],
    code: [StatusNumber.TOTAL],
    isChecked: false,
  },
  {
    state: StatusText[StatusNumber.PENDIENTE],
    code: [StatusNumber.PENDIENTE],
    isChecked: false,
  },
  {
    state: StatusText[StatusNumber.RECHAZADO],
    code: [StatusNumber.RECHAZADO],
    isChecked: false,
  },
  {
    state: StatusText[StatusNumber.SINESTADO],
    code: [StatusNumber.SINOP, StatusNumber.SINESTADO],
    isChecked: false,
  },
];
export default statusInitialState;
