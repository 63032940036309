import { Text } from '@chakra-ui/react';
import { format } from 'date-fns';

import BadgeState from '../../../components/BadgeState';
import ColumnSort from '../../../components/ColumnSort';
import DrawerSnackbar from '../../../components/DrawerSnackbar';
import TextEllipsisWithTooltip from '../../../components/TextEllipsisWithTooltip';
import StatusNumber from '../../../interfaces/IStatusTypes';
import { TooltipPaymentOrderDisabled } from '../../../text/generalText';
import transformNumberToCurrency, {
  getDocumentIdStringFromList,
} from '../../../utils/converters';
import isDateValid from '../../../utils/dateUtils';

export default sortFunction => [
  {
    id: 'cliente',
    header: () => (
      <Text textStyle='titleSmBold' paddingLeft='12px'>
        Cliente
      </Text>
    ),
    accessorFn: issuedDocument => issuedDocument.client_name,
    cell: info => (
      <TextEllipsisWithTooltip
        text={info.getValue() as string}
        textStyle='labelLgBold'
      />
    ),
  },
  {
    id: 'estado',
    header: () => (
      <Text textStyle='titleSmBold' padding='12px'>
        Estado
      </Text>
    ),
    accessorFn: issuedDocument => issuedDocument.document_status,
    cell: info => <BadgeState status={info.getValue() as string} />,
  },
  {
    id: 'tipoDoc',
    header: () => (
      <Text textStyle='titleSmBold' padding='12px' width='100%' align='left'>
        Tipo de documento
      </Text>
    ),
    accessorFn: issuedDocument => issuedDocument.document_type,
    cell: info => (
      <Text textStyle='bodyMd' align='left' padding='12px'>
        {info.getValue() ? (info.getValue() as string) : '-'}
      </Text>
    ),
  },
  {
    id: 'nroDoc',
    header: () => (
      <Text textStyle='titleSmBold' paddingLeft='12px' align='left'>
        N° de documento
      </Text>
    ),
    accessorFn: issuedDocument => issuedDocument.document_number,
    cell: info => (
      <TextEllipsisWithTooltip
        text={info.getValue() as string}
        maxWidth='150px'
        placement='top'
      />
    ),
  },
  {
    id: 'fechaEmision',
    header: () => (
      <Text textStyle='titleSmBold' display='flex' align='left'>
        Fecha de emisión
        <ColumnSort onSort={order => sortFunction('date', order)} />
      </Text>
    ),
    accessorFn: issuedDocument => issuedDocument.date,
    cell: info => (
      <Text textStyle='bodyMd' align='left'>
        {info.getValue() &&
        info.getValue() !== '' &&
        isDateValid(info.getValue())
          ? format(new Date(info.getValue()?.replace(/-/g, '/')), 'dd/MM/yyyy')
          : '-'}
      </Text>
    ),
  },
  {
    id: 'importe',
    header: () => (
      <Text
        textStyle='titleSmBold'
        width='100%'
        align='right'
        paddingRight='12px'
      >
        Importe
      </Text>
    ),
    accessorFn: issuedDocument => issuedDocument,
    cell: info => {
      const { amount, currency } = info.getValue();
      return (
        <Text textStyle='labelLgBold' align='right' paddingRight='22px'>
          {info.getValue() ? transformNumberToCurrency(amount, currency) : '-'}
        </Text>
      );
    },
  },
  {
    id: 'ordenPago',
    header: () => (
      <Text textStyle='titleSmBold' width='100%' align='center'>
        Orden de Pago
      </Text>
    ),
    accessorFn: ({
      amount,
      client_name,
      document_status,
      related_documents,
    }) => [amount, client_name, document_status, related_documents],
    cell: info => {
      return (
        <span>
          <DrawerSnackbar
            documentId={getDocumentIdStringFromList(info.getValue()[3])}
            title={info.getValue()[1]}
            enabled={info.getValue()[3].length > 0}
            messageTooltip={getDocumentMessage(info.getValue()[2])}
            status={info.getValue()[2]}
          />
        </span>
      );
    },
  },
];

const getDocumentMessage = status => {
  let message = null;
  switch (status) {
    case StatusNumber.PENDIENTE:
    case StatusNumber.APROBADO:
      message = TooltipPaymentOrderDisabled.pendiente;
      break;
    case StatusNumber.TOTAL:
    case StatusNumber.PARCIAL:
    case StatusNumber.SINOP:
    case StatusNumber.SINESTADO:
      message = TooltipPaymentOrderDisabled.noDisponible;
      break;
    default:
      break;
  }
  return message;
};
