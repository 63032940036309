import { BreadcrumbComponent } from '@wow/breadcrumb';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import CobranzasPanel from './styled';

function MainLayout() {
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  function nomalizeUrl(str: string) {
    const words = str.split(/(?=[A-Z])/);
    return words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  useEffect(() => {
    const sections = location.pathname.split('/').filter(item => item !== '');
    let path = '';
    const breadcrumbSections = [];
    for (let i = 0; i < sections.length; i = +1) {
      const temPath = `${path}/${sections[i]}`;
      path = temPath;
      const label = sections[i]
        .split(' ')
        .map(word => nomalizeUrl(word))
        .join(' ');
      breadcrumbSections.push({
        key: i,
        id: i,
        label,
        link: '',
        isCurrentPage: i === sections.length - 1,
        onClick: e => {
          e.preventDefault();
          navigate(temPath);
        },
      });
    }
    setBreadcrumbItems(breadcrumbSections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <CobranzasPanel>
      <BreadcrumbComponent items={breadcrumbItems} marginBottom='17px' />
      <Outlet />
    </CobranzasPanel>
  );
}

export default MainLayout;
