// COLORES

export const colors = {
  light: '#74777F',
  lightgray: '#44474F',
  gray: 'rgba(24, 25, 27, 0.12)',
  white: 'white',
  backGray: 'rgba(56, 56, 56, 0.38)',
  textgray: '#2F3034',
  blue: '#2172DC',
  borderGray: '#c7c6ca',
  transparent: 'transparent',
  rowBackgroundTable: 'rgba(33, 114, 220, 0.12)',
  rowHeaderBackgroundTable: '#F2F0F4',
  black: '#000000',
  disabled: '#ACABAF',
  lightBlueNew: '#EBF0FF',
  Secondary100: '#565E71',
  gray4: '#C7C6CA',
  blueGray: '#002e68',
  AzureishWhite: '#d6e2ff',
};

export const fonts = {
  body: 'Inter, sans-serif',
  bodyBold: 'Inter Bold, sans-serif',
};
// BORDERS
export const borders = {
  grayBorder: '0px -3px 8px 4px rgba(0, 0, 0, 0.1)',
};

// BOX SHADOWS

export const boxShadows = {
  gray: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
  grayHover:
    '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3);',
  grayAccordion:
    '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
};

export const sizeFont = {
  fontSize14: '14px',
  fontSize15: '15px',
};

export default { colors };
