import { Box, Button, Text } from '@chakra-ui/react';

import INoResultsView from '../../interfaces/INoResultView';
import { DataNoResultsView, EmptyDocument } from '../../text/generalText';
import EMPTY_RESULTS_CONF from './config';
import { Container, Link } from './styled';

function NoResultsView({
  hasButton = false,
  hasLink = false,
  onRetry,
  variantButton = 'primary-text',
  variant,
}: INoResultsView) {
  const noResultConfig = EMPTY_RESULTS_CONF[variant];

  return (
    <Container>
      <Box
        className={`no-result-picture ${noResultConfig.picture}`}
        data-testid='image'
      />
      <Text textStyle='h4Bold' className='no-result-title'>
        {noResultConfig.title}
      </Text>
      <Text textStyle='bodyLg' className='no-result-desc'>
        {noResultConfig.subtitle}
      </Text>
      {hasButton && (
        <Box className='no-result-create-button'>
          <Button variant={variantButton} onClick={onRetry} size='md'>
            {DataNoResultsView.buttonText}
          </Button>
        </Box>
      )}
      {hasLink && (
        <Text className='marginLink' textStyle='labelLgBoldUnderline'>
          <Link
            target='blank'
            href={process.env.COBRANZAS_EXTERNAL_URL}
            rel='noopener noreferrer'
          >
            {EmptyDocument.link}
          </Link>
        </Text>
      )}
    </Container>
  );
}

export default NoResultsView;
