import styled from 'styled-components';

const DrawerItemPanelContainer = styled.div`
  .availableFiles {
    padding: 12px 12px;
    border-radius: 8px;
    border-color: var(--chakra-colors-grey-60);
    border-style: solid;
    border-width: 1px;
  }
  .success {
    color: var(--chakra-colors-success-100);
  }

  .error {
    color: var(--chakra-colors-secondary-100);
  }

  .circleIcon {
    margin-right: 10px;
    width: 22px;
    height: 22px;
  }
  .leftCircle {
    display: flex;
    flex-direction: row;
  }

  .rightCircle {
    display: flex;
    flex-direction: row;
    margin-left: 40px;
  }

  .textCircle {
    margin: auto;
    color: var(--chakra-colors-grey-120);
  }

  .circlesFiles {
    display: flex;
    flexdirection: row;
  }
`;

export default DrawerItemPanelContainer;
