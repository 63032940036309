import { Badge } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import StatusNumber, { StatusText } from '../../interfaces/IStatusTypes';
import Container from './styled';

interface ComponentProps {
  status: string;
}

const initialState = { status: ' ', variant: ' ' };

function BadgeState({ status }: ComponentProps) {
  const [badge, setBadge] = useState(initialState);

  useEffect(() => {
    switch (status) {
      case StatusNumber.PARCIAL:
      case StatusNumber.TOTAL:
        return setBadge({
          status: StatusText[status],
          variant: 'success-light',
        });
      case StatusNumber.PENDIENTE:
      case StatusNumber.APROBADO:
        return setBadge({
          status: StatusText[status],
          variant: 'warning-light',
        });
      case StatusNumber.RECHAZADO:
        return setBadge({
          status: StatusText[status],
          variant: 'error-light',
        });
      case StatusNumber.SINOP:
      default:
        return setBadge({
          status: StatusText[status],
          variant: 'neutral-light',
        });
    }
  }, [status]);

  return (
    <span>
      <Container>
        <Badge variant={`${badge.variant}`} data-testid={`${badge.variant}`}>
          <p>{badge.status}</p>
        </Badge>
      </Container>
    </span>
  );
}

export default BadgeState;
