import { Icon } from '@chakra-ui/react';
import { ChevronDown, ChevronUp } from 'react-feather';

import { IColumnSortProp } from '../../interfaces/IColumnSortProp';
import { ButtonChevron } from './styled';

function ColumnSort({ onSort }: IColumnSortProp) {
  return (
    // esto queda por ahora asi hasta resolver un error
    <span>
      <ButtonChevron onClick={() => onSort('asc')}>
        <Icon w={4} h={4} as={ChevronUp} data-testid='order-up' />
      </ButtonChevron>
      <ButtonChevron onClick={() => onSort('desc')}>
        <Icon w={4} h={4} as={ChevronDown} data-testid='order-down' />
      </ButtonChevron>
    </span>
  );
}
export default ColumnSort;
