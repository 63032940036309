import { Spinner, Text } from '@chakra-ui/react';
import React from 'react';

import StyledLoadingScreen from './styled';

interface ILoadingScreen {
  title: string;
  description: string;
}

function LoadingScreenWhite({ title, description }: ILoadingScreen) {
  return (
    <StyledLoadingScreen>
      <div className='loading-screen'>
        <div className='loading-screen__spinner'>
          <Spinner
            thickness='30px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
            width='3rem'
            height='3rem'
            borderWidth='0.2rem'
            data-testid='spinner-loading'
          />
        </div>
        <Text textStyle='h4Bold' className='loading-screen__title'>
          {title}
        </Text>
        <Text textStyle='bodyLg' className='loading-screen__description'>
          {description}
        </Text>
      </div>
    </StyledLoadingScreen>
  );
}

export default LoadingScreenWhite;
