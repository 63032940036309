import {
  PlacementWithLogical,
  Text,
  TextProps,
  Tooltip,
} from '@chakra-ui/react';

interface TextEllipsisProps {
  text: string;
  placement?: PlacementWithLogical;
}

function TextEllipsisWithTooltip(props: TextEllipsisProps & TextProps) {
  const { maxWidth, placement = 'start-start', text } = props;
  const pixelPerCharacter = 10;
  const currentWidth = maxWidth ? +maxWidth.toString().split('px')[0] : 150;
  return (
    <Tooltip
      direction='rtl'
      hasArrow
      label={text}
      placement={placement}
      isDisabled={text.length * pixelPerCharacter < currentWidth}
    >
      <Text
        textStyle='bodyMd'
        textAlign='left'
        padding='12px'
        display='block'
        textOverflow={
          text.length * pixelPerCharacter < currentWidth
            ? 'initial'
            : 'ellipsis'
        }
        maxWidth='145px'
        overflow='hidden'
        whiteSpace='nowrap'
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {text}
      </Text>
    </Tooltip>
  );
}

export default TextEllipsisWithTooltip;
