// eslint-disable-next-line import/no-unresolved
import { getToken } from '@react-ib-mf/style-guide-ui';
import axios from 'axios';

import { IIssuedDocsResponse } from '../interfaces/IIssuedDocsResponse';
import { IPaymentOrdersResponse } from '../interfaces/IPaymentOrdersResponse';

const newAxios = axios.create({
  baseURL: process.env.COBRANZAS_BASE_URL,
  headers: {
    Authorization: `Bearer ${getToken()}`,
    user_key: `${process.env.API_USER_KEY}`,
  },
});

export const getIssuedDocuments = (
  limitProp,
  pageProp,
  sortProp,
  sortOrder,
  status?,
) =>
  newAxios.get<IIssuedDocsResponse>(`documents/issued`, {
    params: {
      limit: limitProp,
      page: pageProp,
      sort: `${sortProp}:${sortOrder}`,
      ...(status ? { document_status: status } : {}),
    },
  });

export const getPaymentOrder = (
  documentId: string,
  sortProp: string,
  sortOrder: string,
  limit = 15,
  page = 1,
) => {
  return newAxios.get<IPaymentOrdersResponse>(
    `payments/orders?limit=${limit}&page=${page}&sort=${sortProp}:${sortOrder}&document-id=${documentId}`,
  );
};

export const getDownloadZIP = (keys: string) => {
  return newAxios.get(`payments/pdfsFiles?keys=${keys}`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
};

export default {
  getIssuedDocuments,
  getPaymentOrder,
  getDownloadZIP,
};
