import styled from 'styled-components';

const Container = styled.div`
  margin-top: 30px;
  padding-left: 12px;
  padding-right: 20px;

  .boxDocumentsList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }

  .textRight {
    text-align: right;
  }

  .firstColumn {
    width: 35%;
  }

  .secondColumn {
    width: 25%;
  }

  .thirdColumn {
    width: 30%;
  }
`;

export default Container;
