import { Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import React from 'react';

import { RelatedDocumentResponse } from '../../../interfaces/IPaymentOrdersResponse';
import { DrawerPaymentOrders } from '../../../text/generalText';
import transformNumberToCurrency from '../../../utils/converters';
import { Box } from '../../../views/Home/styled';
import Container from './styled';

interface IAccordionDrawerItemDocumentIncluded {
  documentIncluded: RelatedDocumentResponse[];
}
function AccordionDrawerItemDocumentIncluded({
  documentIncluded,
}: IAccordionDrawerItemDocumentIncluded) {
  const getTextForCurrency = (currency: string, amount: number): string => {
    return amount ? transformNumberToCurrency(amount, currency) : '-';
  };

  const renderDocumentIncluded = () => {
    return documentIncluded?.map(document => {
      return (
        <Box className='boxDocumentsList' key={document.documentNumber}>
          <div className='firstColumn'>
            <Text textStyle='labelLgBold'>
              {DrawerPaymentOrders.nroDocumento}
            </Text>
            <Text textStyle='bodyMd'>{document.documentNumber}</Text>
          </div>
          <div className='secondColumn'>
            <Text textStyle='labelLgBold'>
              {DrawerPaymentOrders.fechaEmision}
            </Text>
            <Text textStyle='bodyMd'>
              {document.date
                ? format(
                    new Date(document.date?.replace(/-/g, '/')),
                    'dd/MM/yyyy',
                  )
                : '-'}
            </Text>
          </div>
          <div className='thirdColumn'>
            <Text textStyle='labelLgBold' className='textRight'>
              {DrawerPaymentOrders.importe}
            </Text>
            <Text textStyle='bodyMd' className='textRight'>
              {getTextForCurrency(document.currency, document.amount)}
            </Text>
          </div>
        </Box>
      );
    });
  };

  return (
    <Container>
      <Text textStyle='labelLgBold'>
        {DrawerPaymentOrders.titleDocumentIncluded}
      </Text>

      {renderDocumentIncluded()}
    </Container>
  );
}

export default AccordionDrawerItemDocumentIncluded;
