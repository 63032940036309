/* eslint-disable import/no-unresolved */
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Icon,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { pushAnalyticsEvent } from '@wow/utils';
import React, { useEffect, useState } from 'react';
import { Download, File } from 'react-feather';

import { DrawerProps } from '../../interfaces/IDrawerProps';
import { IPaymentOrderResponse } from '../../interfaces/IPaymentOrdersResponse';
import { getDownloadZIP, getPaymentOrder } from '../../services/api';
import { DrawerPaymentOrders, UploadScreen } from '../../text/generalText';
import BadgeState from '../BadgeState';
import AccordionDrawer from '../DrawerAccordion';
import LoadingScreenWhite from '../LoadingScreenWhite';
import NoResultsView from '../NoResultsView';
import {
  Btn,
  ButtonDrawer,
  DrawerBodyStyle,
  DrawerContentStyle,
  DrawerFooterStyle,
  icon,
  iconClose,
  iconCloseBlue,
  NoConnection,
  TitleStyle,
} from './styled';

function DrawerDetail({
  title,
  status,
  documentId,
  enabled = true,
  messageTooltip,
  snackbarAction,
  retryDownload,
  setRetryDownload,
}: DrawerProps) {
  const [paymentOrders, setPaymentOrders] = useState<IPaymentOrderResponse[]>(
    [],
  );
  const [totalFiles, setTotalFiles] = useState(0);
  const [filesKey, setFilesKey] = useState('');
  const [hasErrorConnection, setHasErrorConnection] = useState(false);
  const [clickInOpen, setClickInOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (clickInOpen) {
      fetchPaymentOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickInOpen]);

  useEffect(() => {
    if (retryDownload) {
      setRetryDownload(false);
      handleOnClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retryDownload]);

  const fetchPaymentOrders = () => {
    setIsLoading(true);
    getPaymentOrder(documentId, 'date', 'desc')
      .then(res => {
        setIsLoading(false);
        setPaymentOrders(res.data.data.payment_orders);
        setTotalFiles(res.data.data.totalFiles);
        setFilesKey(res.data.data.filesKeys);
        setHasErrorConnection(false);
      })
      .catch(() => {
        setIsLoading(false);
        setHasErrorConnection(true);
      });
  };

  const handleOnOpen = () => {
    setClickInOpen(true);
    onOpen();
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Ordenes de pago',
      item_id: 'Ver detalle orden de pago',
    });
  };

  const handleOnClick = () => {
    pushAnalyticsEvent({
      event: 'file_download',
    });
    getDownloadZIP(filesKey)
      .then(response => {
        if (response.data) {
          setIsLoading(false);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Archivos.zip`);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(() => {
        setIsLoading(false);
        snackbarAction(true);
      });
  };

  const getTextForDownloadFile = () => {
    return DrawerPaymentOrders.downloadButton(totalFiles);
  };

  return (
    <>
      <Btn>
        <Tooltip
          direction='rtl'
          hasArrow
          label={messageTooltip}
          placement='left'
          isDisabled={!messageTooltip || enabled}
        >
          <IconButton
            data-testid='orders-icon'
            aria-label='File icon'
            icon={
              enabled ? (
                <Icon as={File} style={icon} className='iconBtnPrimary' />
              ) : (
                <Icon as={File} style={icon} className='iconBtnGrey' />
              )
            }
            onClick={handleOnOpen}
            size='i-lg'
            variant='i-primary-text'
            className='iconBtn'
            isDisabled={!enabled}
          />
        </Tooltip>
      </Btn>
      <Drawer isOpen={isOpen} placement='right' onClose={onClose} size='lg'>
        <DrawerOverlay />
        <DrawerContent style={DrawerContentStyle}>
          {isLoading && (
            <>
              <DrawerCloseButton
                style={isLoading ? iconCloseBlue : iconClose}
              />
              <LoadingScreenWhite
                title={UploadScreen.title}
                description={UploadScreen.subTitle}
              />
            </>
          )}
          <DrawerCloseButton
            data-testid='btn-close'
            style={isLoading ? iconCloseBlue : iconClose}
          />
          {hasErrorConnection ? (
            <Box style={NoConnection}>
              <NoResultsView
                variant='ERROR_CONN'
                hasButton
                onRetry={fetchPaymentOrders}
              />
            </Box>
          ) : (
            <>
              <DrawerBody style={DrawerBodyStyle}>
                <BadgeState status={status} />
                <Text
                  data-testid='title-drawer'
                  textStyle='h3'
                  style={TitleStyle}
                >
                  {title}
                </Text>
                <AccordionDrawer paymentOrders={paymentOrders} />
              </DrawerBody>
              <DrawerFooter style={DrawerFooterStyle}>
                <Button
                  size='md'
                  spinnerPlacement='start'
                  variant='primary'
                  isDisabled={totalFiles === 0}
                  rightIcon={<Download />}
                  onClick={handleOnClick}
                  style={ButtonDrawer}
                >
                  {getTextForDownloadFile()}
                </Button>
              </DrawerFooter>
            </>
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default DrawerDetail;
