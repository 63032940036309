import { IconButton } from '@chakra-ui/react';
import styled from 'styled-components';

import { colors } from '../../styled/generalStyles';

const DrawerBody = styled.div`
  margin: 56px 36px 0px 36px;
`;

const Btn = styled.div`
  background: ${colors.transparent};
  color: ${'var(--chakra-colors-accent-500)'};
  border: 'none';
  .iconBtn {
    background-color: transparent !important;
    position: inherit !important;
  }
  .iconBtnPrimary {
    color: ${'var(--chakra-colors-primary-100)'};
    position: inherit !important;
  }

  .iconBtnGrey {
    color: ${'var(--chakra-colors-grey-60)'};
    position: inherit !important;
  }
`;

const Td = styled.td`
  margin: 0px;
  padding: 23px 12px 23px 12px;
  border-bottom: 1px solid ${colors.borderGray};
`;

const DivTable = styled.div`
  margin-top: 12px;
`;

const P = styled.p`
  text-align: right;
`;

export const icon = {
  width: '26px',
  height: '32px',
  boxShadow: 'none',
};

export const iconClose = {
  background: colors.white,
  border: 'none',
  color: colors.blue,
  cursor: 'pointer',
  fontSize: '14px',
  padding: '0px',
  top: '16px',
  right: '16px',
  marginBottom: '8px',

  '&:active': {
    background: 'none',
  },

  '&:hover': {
    background: 'none',
  },

  '&:focus': {
    background: 'none',
    boxShadow: 'none',
  },
};

export const iconCloseBlue = {
  cursor: 'pointer',
  color: `${colors.blue}`,
};

export const DrawerBodyStyle = {
  marginTop: '40px',
};

export const TitleStyle = {
  marginTop: '14px',
};

export const NoConnection = {
  margin: 'auto',
};

export const DrawerContentStyle = {
  borderRadius: '8px 0px 0px 8px',
};

export const IconButtonStyled = styled<{ disabled: boolean }>(IconButton)`
  background-color: transparent !important;
  border: none;
  padding: 0;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

export const DrawerFooterStyle = {
  borderTopWidth: '1px',
};

export const ButtonDrawer = {
  marginRight: '64px',
};

export { Btn, DivTable, DrawerBody, P, Td };
