import styled from '@emotion/styled';

import { colors, fonts, sizeFont } from '../../styled/generalStyles';

export const TableStyled = styled.table`
  border-collapse: separate;
  border-spacing: 0 6px;
  width: 100%;

  .grid-container {
    display: table-row;
    gap: 16px;
    align-items: center;
    border-radius: 8px;
    padding: 10px;
  }

  .alignCenter {
    text-align: center;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .alignEnd {
    text-align: end;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .alignRight {
    text-align: right;
  }

  .marginRight25 {
    margin-right: 25px;
  }
  .line {
    display: block;
    height: 1px;
    width: 100%;
    background: black;
  }

  .alignCenterHeaderState {
    justify-content: center;
    min-width: 110px;
  }

  .alignRightHeader {
    display: flex;
    justify-content: right;
  }

  .flexColumn {
    display: grid;
  }
  .maxWidth {
    max-width: 165px;
  }

  button {
    margin: 0;
    padding: 0px 0px 0px 5px;
    background-color: transparent;
    // display: grid;
    align-content: center;
    font-size: ${sizeFont.fontSize15};
    height: 10px;
    &:hover {
      filter: invert(0.5);
    }
  }

  thead {
    tr {
      background: ${colors.rowHeaderBackgroundTable};
    }

    th {
      white-space: nowrap;
      padding-right: 12px;
    }

    th:first-of-type {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    th:last-of-type {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  tbody {
    tr {
      margin-bottom: 6px;
      border-bottom: 1px solid #e3e3e4;
      text-align: center;
      &:hover {
        background-color: #e3e3e4;
      }
    }
    td {
      font-family: ${fonts.body};
      font-size: ${sizeFont.fontSize14};
      color: ${colors.textgray};
    }
    td:has(.error-message) {
      padding-bottom: 12px;
    }
    tr:has(.rowEnabled) {
      background: ${colors.rowBackgroundTable};
    }
    tr:has(.rowDisabled) {
      input {
        pointer-events: none;
        background: ${colors.gray};
        border: 1px solid ${colors.backGray};
        border-radius: 4px;
        letter-spacing: 0.25px;
        color: ${colors.backGray};
      }
    }
    tr:has(input[type='checkbox']:disabled) {
      background: ${colors.white};
      font-family: ${fonts.body};
      font-size: ${sizeFont.fontSize14};
      input {
        pointer-events: none;
        border: 1px solid ${colors.backGray};
        border-radius: 4px;
        letter-spacing: 0.25px;
        color: ${colors.backGray};
        background-color: rgba(24, 25, 27, 0.12);
      }
    }

    .disableHover {
      pointer-events: none;
    }

    td:first-of-type {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    td:last-of-type {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .flexIcon {
    display: flex;
    align-items: center;
  }

  .emptyContent:hover {
    background-color: ${colors.white};
  }
`;

export const Cell = styled.span<{ alignContent?: string }>`
  display: flex;
  margin: auto;
  padding: 12px 0;
  align-items: center;
  justify-content: ${({ alignContent }) => alignContent || 'start'};
`;
