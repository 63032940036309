// eslint-disable-next-line import/no-unresolved
import { WithPermissions } from '@react-ib-mf/style-guide-ui';
import { ErrorContentComponent, ErrorContentProps } from '@wow/error-content';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import MainLayout from './layouts/MainLayout';
import Home from './views/Home';

const ErrorContent: ErrorContentProps = {
  titleText: 'No podemos mostrar la página.',
  bodyText: 'Notamos que no tenés permisos para visualizar esta sección.',
  linkText: 'Reintentar',
  callbackAction: () => window.location.reload(),
  accessError: 'content',
  isRouter: false,
};

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path='/'
          element={
            <WithPermissions
              permission={[
                '7908d6ac8412333d43dd68e9926263ef0625e2a395e7a7d0eed4b9d96f3faf4b',
              ]}
              showErrorPage
            >
              <MainLayout />
            </WithPermissions>
          }
        >
          <Route
            path='*'
            element={<ErrorContentComponent ErrorContentProps={ErrorContent} />}
          />
          <Route path='cobranzas' element={<Home />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
