/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import {
  Button,
  Checkbox,
  Icon,
  Menu,
  MenuButton,
  Portal,
  Text,
} from '@chakra-ui/react';
import { LoadingScreenComponent as LoadingScreen } from '@wow/loading-screen';
import { pushAnalyticsEvent } from '@wow/utils';
import { useEffect, useState } from 'react';
import { ChevronDown, RefreshCcw, X } from 'react-feather';

import useChakraToast from '../../hooks/useChakraToast';
import { FILTER_STATUS, IFilterProps } from '../../interfaces/IFilterProps';
import { StatusText } from '../../interfaces/IStatusTypes';
import {
  DataFilter,
  Restore,
  SnackbarText,
  UploadScreen,
} from '../../text/generalText';
import statusInitialState from './filterConfig';
import {
  Container,
  ContainerPortal,
  CustomMenuList,
  customMenuList,
} from './styled';

function Filter({
  filterStatus,
  isExistDocuments,
  isLoading,
  onFilterApply,
}: IFilterProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [draftCounter, setDraftCounter] = useState(0);
  const [counter, setCounter] = useState(0);
  const [cleanButtonEnabled, setCleanButtonEnabled] = useState(false);
  const [chipText, setChipText] = useState(DataFilter.buttonFilter);
  const [filteredState, setFilteredState] = useState(statusInitialState);
  const [statusList, setStatusList] = useState(statusInitialState);
  const { ToastUtilities } = useChakraToast();
  const DURATION_IN_MILLISECONDS = 10000;

  useEffect(() => {
    if (isOpen) {
      setStatusList(filteredState);
    }
  }, [isOpen]);

  useEffect(() => {
    if (filterStatus === FILTER_STATUS.SUCCESS) {
      setFilteredState([...statusList]);
      setCounter(getFilterSelectedStatus(statusList).length);
    }
    if (filterStatus === FILTER_STATUS.FAILED) {
      ToastUtilities.error({
        description: SnackbarText.errorSnackBar,
        duration: DURATION_IN_MILLISECONDS,
        toastOptions: {
          isCtaLabel: true,
          ctaLabel: SnackbarText.buttonRetry,
          ctaOnClick: () => handleCloseSnackbar(),
        },
      });
    }
  }, [filterStatus]);

  useEffect(() => {
    setCleanButtonEnabled(draftCounter > 0);
  }, [draftCounter]);

  useEffect(() => {
    const statusCode = getFilterSelectedStatus(statusList);
    setDraftCounter(statusCode.length);
    updateChip(getFilterSelectedStatus(statusList));
  }, [statusList]);

  const handleChangeCheckbox = (isChecked, code) => {
    setStatusList(
      statusList.map(statusItem => {
        if (statusItem.code === code) {
          // Create a *new* object with changes
          return { ...statusItem, isChecked };
        }
        return statusItem;
      }),
    );
  };

  const handleCleanFilters = () => {
    setStatusList([...statusInitialState]);
    setCleanButtonEnabled(false);
  };

  const handleApply = () => {
    const statusCodes = getFilterSelectedStatus(statusList);
    updateChip(statusCodes);
    setIsOpen(!isOpen);
    onFilterApply(statusCodes);
    pushAnalyticsEvent({
      event: 'filter',
      filter_type: 'Estado',
    });
  };

  const handleRestoreFilter = () => {
    setStatusList([...statusInitialState]);
    setChipText(DataFilter.buttonFilter);
    setIsOpen(false);
    onFilterApply([]);
    pushAnalyticsEvent({
      event: 'remove_filters',
      selection: 'Restablecer',
    });
  };

  const handleCloseSnackbar = () => {
    if (filterStatus === FILTER_STATUS.FAILED) {
      updateChip();
      setStatusList([...filteredState]);
      ToastUtilities.closeAll();
    }
  };

  const getFilterSelectedStatus = (filterList): string[] =>
    filterList.reduce((acum, currentValue) => {
      if (currentValue.isChecked) {
        acum.push(...currentValue.code);
      }
      return acum;
    }, []);

  const updateChip = (statusCodes = undefined) => {
    let currentCodes = statusCodes ?? getFilterSelectedStatus(filteredState);
    currentCodes = currentCodes.filter(currentCode => currentCode !== '0');

    switch (currentCodes?.length) {
      case 0:
        setChipText(DataFilter.buttonFilter);
        break;
      case 1:
        setChipText(StatusText[`${currentCodes[0]}`]);
        break;
      default:
        setChipText(`${DataFilter.buttonFilter} (${currentCodes?.length})`);
    }
  };

  return (
    <Container>
      <Text textStyle='labelLgBold' className='marginFilter'>
        {DataFilter.subtitle}
      </Text>
      <Menu isOpen={isOpen}>
        <MenuButton
          disabled={!isExistDocuments && chipText === DataFilter.buttonFilter}
          className={
            isOpen || chipText !== DataFilter.buttonFilter
              ? 'menuButtonActive'
              : 'menuButtonEnabled'
          }
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className='flexMenuButton'>
            <Text className='textMenuButton' textStyle='labelLg'>
              {chipText}
            </Text>
            <ChevronDown
              className={
                !isExistDocuments && chipText === DataFilter.buttonFilter
                  ? 'iconMenuButtonGrey'
                  : 'iconMenuButton'
              }
            />
          </div>
        </MenuButton>
        {isLoading && (
          <LoadingScreen
            title={UploadScreen.title}
            description={UploadScreen.subTitle}
          />
        )}
        <Button
          aria-label='restore-filter-button'
          variant='primary-text'
          onClick={handleRestoreFilter}
          leftIcon={<RefreshCcw />}
          marginLeft='auto'
          isDisabled={counter === 0}
          size='md'
        >
          {Restore.buttonRestore}
        </Button>
        <Portal>
          <ContainerPortal>
            <CustomMenuList
              sx={customMenuList}
              zIndex='0px'
              data-testid='customMenuList'
            >
              <div className='menuItem'>
                <Text textStyle='labelLgBold' className='subtitleMenuItem'>
                  {DataFilter.menuSubtitle}
                </Text>
                <Icon
                  id='IconX'
                  className='iconCloseButton'
                  h={6}
                  w={6}
                  as={X}
                  onClick={() => {
                    updateChip();
                    setIsOpen(!isOpen);
                  }}
                />
              </div>
              <hr className='menuDivider' />
              <div className='menuItemCheckbox'>
                {statusList.map(item => (
                  <Checkbox
                    name='checkboxCompany'
                    key={item.code.join(',')}
                    className={
                      item.isChecked ? 'textCheckboxBlue' : 'textCheckbox'
                    }
                    onChange={e =>
                      handleChangeCheckbox(e.target.checked, item.code)
                    }
                    isChecked={item.isChecked}
                  >
                    {item.state}
                  </Checkbox>
                ))}
              </div>
              <hr className='menuDivider' />
              <div className='menuItem'>
                <Button
                  onClick={handleCleanFilters}
                  isDisabled={!cleanButtonEnabled}
                  variant='primary-text'
                  size='md'
                >
                  {DataFilter.buttonDelete}
                </Button>
                <Button
                  onClick={handleApply}
                  size='md'
                  variant='primary'
                  date-testid='button-apply'
                >
                  {DataFilter.buttonApply}
                </Button>
              </div>
            </CustomMenuList>
          </ContainerPortal>
        </Portal>
      </Menu>
    </Container>
  );
}

export default Filter;
