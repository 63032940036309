import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { CheckCircle, MinusCircle } from 'react-feather';

import { FileDocumentResponse } from '../../../interfaces/IPaymentOrdersResponse';
import { DrawerPaymentOrders } from '../../../text/generalText';
import DrawerItemPanelContainer from './styled';

interface IAccordionDrawerItemPanel {
  files: FileDocumentResponse[];
}

function AccordionDrawerItemAvalaibleFiles({
  files,
}: IAccordionDrawerItemPanel) {
  const existFileType = (type: string): boolean => {
    const filesWithType = files?.filter(element => element.type === type);
    return filesWithType ? filesWithType.length > 0 : false;
  };

  const renderCircle = (exist: boolean, type: string, position: string) => {
    return (
      <Box>
        {exist ? (
          <Box
            className={`success ${
              position === 'right' ? 'rightCircle' : 'leftCircle'
            }`}
          >
            <CheckCircle className='circleIcon' />
            <Text className='textCircle' textStyle='labelMd'>
              {getTextFromType(type)}
            </Text>
          </Box>
        ) : (
          <Box
            className={`error ${
              position === 'right' ? 'rightCircle' : 'leftCircle'
            }`}
          >
            <MinusCircle className='circleIcon' />
            <Text className='textCircle' textStyle='labelMd'>
              {getTextFromType(type)}
            </Text>
          </Box>
        )}
      </Box>
    );
  };

  const getTextFromType = (type: string): string => {
    switch (type) {
      case 'P':
        return DrawerPaymentOrders.paymentOrder;
      case 'R':
        return DrawerPaymentOrders.retetionCertificate;
      default:
        return '';
    }
  };

  return (
    <DrawerItemPanelContainer>
      <Box className='availableFiles'>
        <Text textStyle='labelLgBold' style={{ marginBottom: '12px' }}>
          {DrawerPaymentOrders.titleFilesAvalaible}
        </Text>
        <Box className='circlesFiles'>
          {renderCircle(existFileType('R'), 'R', 'left')}
          {renderCircle(existFileType('P'), 'P', 'right')}
        </Box>
      </Box>
    </DrawerItemPanelContainer>
  );
}

export default AccordionDrawerItemAvalaibleFiles;
