import styled from 'styled-components';

import noData from '../../assets/images/binoculars.svg';
import noresults from '../../assets/images/noResultsView.svg';

export const Container = styled.div`
  .no-results {
    background: url(${noresults}) no-repeat;
  }

  .no-data {
    background: url(${noData}) no-repeat;
  }

  .no-result-picture {
    text-align: center;
    margin: auto;
    margin-top: 60px;
    height: 120px;
    width: 120px;
  }

  .no-result-title {
    text-align: center;
    margin-top: 25px;
  }

  .no-result-desc {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 20px;
  }

  .no-result-create-button {
    display: flex;
    justify-content: center;
  }

  .marginLink {
    margin-bottom: 40px;
  }
`;

export const Link = styled.a`
  color: blue;
  text-decoration: underline;
  text-decoration-skip-ink: underline;
  text-align: center;
`;

export default { Container, Link };
