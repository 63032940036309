import { WizardHome } from '../../text/generalText';

const WizardSteps = [
  {
    title: WizardHome.titleInitInterpagos,
    description: WizardHome.descInitInterpagos,
    target: 'titleInterpagos',
    placement: 'top',
    offsetExtraY: 80,
    offsetExtraX: 300,
  },
  {
    title: WizardHome.titleTable,
    description: WizardHome.descTable,
    target: 'tableDocuments',
    placement: 'bottom',
    offsetExtraY: 147,
    offsetExtraX: 150,
  },
  {
    title: WizardHome.titleFilter,
    description: WizardHome.descFilter,
    target: 'filter',
    placement: 'top',
    offsetExtraY: 107,
    offsetExtraX: 720,
  },
];

export default WizardSteps;
